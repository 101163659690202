<!-- 订单管理-充电订单 -->

<template>
	<div v-if="menberInfo">
		<wDialog ref="detailsDialog" class="add-dialog" title="会员详情" width="80%" btnWidth="140px" top="2vh"
			:hideFooter="true">
			<div>
				<div>基础信息</div>
				<div class="bg-gray flex justify-between flex-wrap padding margin-top radius">
					<div class="basic-item"> <span>会员ID</span><span>{{menberInfo.m_id}}</span> </div>
					<div class="basic-item"> <span>会员昵称</span><span>{{menberInfo.m_username}}</span> </div>
					<div class="basic-item"> <span>手机号</span><span>{{menberInfo.m_mobile}}</span> </div>
					<div class="basic-item"> <span>是否会员</span><span>{{menberInfo.is_member}}</span></div>
					<div class="basic-item"> <span>零钱余额</span><span>{{menberInfo.member_work.mw_surplus_money}}</span>
					</div>
					<div class="basic-item">
						<span>可用积分</span><span>{{menberInfo.member_work.mw_surplus_integral}}</span>
					</div>
					<div class="basic-item"> <span>车牌号</span><span>{{menberInfo.car_num}}</span></div>
					<div class="basic-item"> <span>是否有充电桩</span><span>{{menberInfo.charing_device}}</span></div>
					<div class="basic-item"> <span>注册时间</span><span>{{menberInfo.m_ctime}}</span></div>
					<div class="basic-item"> <span>最后一次登录时间</span><span>{{menberInfo.m_login_time}}</span></div>
				</div>
			</div>
			<div class="order-charge main-cnt margin-top">
				 <el-tabs v-model="extraParame.order_type" class="demo-tabs" @tab-click="changeOrder">
				    <el-tab-pane label="充电订单" name="1" value="1"></el-tab-pane>
				    <el-tab-pane label="充值订单" name="2" value="2"></el-tab-pane>
				    <el-tab-pane label="续费订单" name="3" value="3"></el-tab-pane>
				    <el-tab-pane label="积分明细" name="4" value="4"></el-tab-pane>
				  </el-tabs>
				<div class="content">
					<div
						v-if="extraParame.order_type == '1' || extraParame.order_type == '2' || extraParame.order_type == '3'">
						<commonTable ref="refRoleTable" tableHeight="400px" :ischeck="false" :ispaging="true"
							:extraParame="extraParame" :apiName="Member.getMemberInfo" :columns="tableColumns"
							@calculateTotal="calculateTotal">
							<!-- 合计 -->
							<template #amount v-if="extraParame.order_type == 1 || extraParame.order_type == 3">
								<div class="flex amount">
									<div>
										<span class="amount-title f-w-bold">合计</span>
										<span class="order-total">{{ totalAmount.orderTotal }}</span>

									</div>
								</div>
							</template>
						</commonTable>
					</div>

					<div v-if="extraParame.order_type == '4'">
						<el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
							<el-tab-pane label="全部" name="1"></el-tab-pane>
							<el-tab-pane label="收入" name="2"></el-tab-pane>
							<el-tab-pane label="支出" name="3"></el-tab-pane>
						</el-tabs>
						<commonTable ref="integralTable" tableHeight="400px" :ispaging="false"
							:tableData="integralDatas" :columns="wholeColumns">
							<template #ind_integral='{row}'>
								<div :class="row.ind_integral > 0 ? '' : 'text-red'">{{row.ind_integral}}</div>
							</template>
							<!-- 合计 -->
							<template #amount>
								<div>
									<span class="f-w-bold">合计</span>
									<span class="integral-total">{{ integralTotal }}</span>
								</div>
							</template>
						</commonTable>
					</div>
				</div>
			</div>
		</wDialog>
		<orderDetail ref="refDetailsDialog" :orderDetails="orderDetails"></orderDetail>
	</div>
</template>

<script setup>
	import {
		ref,
		computed,
		watch,
		nextTick,
	} from "vue";
	import {
		Member
	} from "@/plugins/api.js";
	import {
		ElMessage
	} from "element-plus";
	import {
		useStore
	} from "vuex";
	import wDialog from "@/components/w-dialog/w-dialog.vue";
	import commonTable from "@/components/common-table/common-table.vue";
	import orderDetail from "@/views/order/components/orderDetail.vue";

	const store = useStore();
	const menuTokens = computed(() => store.state.menuToken.menuTokens);
	const authData = ref([]);
	// 监听权限
	watch(() => menuTokens.value, (data) => {
		if (data.length) {
			authData.value = data;
		}
	}, {
		deep: true,
		immediate: true,
	})
	/** 表格对象 */
	const refRoleTable = ref(null);
	// 合计
	const totalAmount = ref({
		orderTotal: 0, // 订单合计
		preferentialTotal: 0, // 优惠合计
		totalPaid: 0, // 已付合计
	})
	const detailsDialog = ref(null); // 详情对象
	const orderDetails = ref({}); // 订单详情数据

	let dfColumns = [{
			prop: "orw_no",
			label: "订单编号",
			color: "--text-color",
			showTooltip: true,
			minWidth: 150
		},
		{
			prop: "orw_total_money",
			label: "订单金额",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "orp_no",
			label: "支付单号",
			color: "--text-color",
			showTooltip: true,
			minWidth: 150
		},
		{
			prop: "orw_elec_money",
			label: "电费",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "orw_sevice_money",
			label: "服务费",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "or_status_title",
			label: "订单状态",
			type: "option",
			metaData: ["进行中", "待支付", "已支付"],
			colorData: ["var(--text-blue-color)", "var(--error-color)", "var(--theme-color)", ],
			showTooltip: true,
		},
		{
			prop: "orw_status_title",
			label: "充电状态",
			type: "option",
			metaData: ["充电中", "已完成"],
			colorData: ["var(--text-blue-color)", "var(--theme-color)", ],
			showTooltip: true,
		},
		{
			prop: "s_name",
			label: "充电站",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "e_name",
			label: "充电桩",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "c_name",
			label: "充电枪",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "orw_start_time",
			label: "充电开始",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "orw_end_time",
			label: "充电结束",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "charg_duration",
			label: "充电时长",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "orw_total_power",
			label: "电量",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "total_discount",
			label: "优惠减免合计",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "payed_money",
			label: "已付金额",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "pay_way",
			label: "收款方式",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "orw_system_check_time",
			label: "收款时间",
			color: "--text-color",
			showTooltip: true,
		},
		// {
		// 	type: "operation",
		// 	prop: "",
		// 	label: "操作",
		// 	minWidth: 100,
		// 	bottons: [{
		// 		name: "详情",
		// 		action: "onDetailBtn",
		// 		token: "n_vqZS1xZb0dLpirxShcPZOCduRAnn",
		// 	},],
		// },
	]
	/** 表格配置数据 */
	const tableColumns = ref([]);

	/** 充值订单表格配置数据 */
	let chargeColumns = [{
			prop: "or_no",
			label: "订单编号",
			color: "--text-color",
			showTooltip: true,
			minWidth: 150
		},
		{
			prop: "mwr_money",
			label: "订单金额",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "orp_no",
			label: "支付单号",
			color: "--text-color",
			showTooltip: true,
			minWidth: 150
		},
		{
			prop: "mwr_type_text",
			label: "订单类型",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "or_status_text",
			label: "订单状态",
			type: "option",
			metaData: ["进行中", "待支付", "已支付"],
			colorData: ["var(--text-blue-color)", "var(--error-color)", "var(--theme-color)", ],
			showTooltip: true,
		},
		{
			prop: "mwr_time",
			label: "支付时间",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "m_username",
			label: "用户昵称",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "m_mobile",
			label: "用户手机",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "orpp_name",
			label: "支付方式",
			color: "--text-color",
			showTooltip: true,
		},
	]


	/**
	 * 
	 * 计算合计
	 * 
	 * */
	const calculateTotal = (datas) => {
		totalAmount.value = {
			orderTotal: 0,
			totalPaid: 0,
			preferentialTotal: 0,
		}
		if (datas.length > 0) {
			datas.forEach(item => {
				if (item.or_status_mark == '2') {
					totalAmount.value.orderTotal += Number(item.orw_total_money);
					if (!isNaN(item.payed_money)) {
						totalAmount.value.totalPaid += Number(item.payed_money);
					}
					if (!isNaN(item.total_discount)) {
						totalAmount.value.preferentialTotal += Number(item.total_discount);
					}
				}
				if (extraParame.value.order_type == 3) {
					totalAmount.value.orderTotal += Number(item.orl_renewal_money);
				}

			})
			totalAmount.value.orderTotal = totalAmount.value.orderTotal.toFixed(2);
			totalAmount.value.totalPaid = totalAmount.value.totalPaid.toFixed(2);
			totalAmount.value.preferentialTotal = totalAmount.value.preferentialTotal.toFixed(2);
		}
	}
	/**
	 * 
	 * 查看详情按钮
	 * 
	 * */
	// const refDetailsDialog = ref(null)
	// const onDetailBtn = (row) => {
	// 	Member.getMemberInfo({
	// 		orw_id: row.orw_id,
	// 		type: "order_detail"
	// 	}).then((res) => {
	// 		if (res.Code === 200) {
	// 			refDetailsDialog.value.openDialog();
	// 			orderDetails.value = res.Data;
	// 		} else {
	// 			ElMessage.error(res.Message);
	// 		}
	// 	});
	// }

	let renewColumns = [{
			prop: "orl_no",
			label: "订单编号",
			color: "--text-color",
			showTooltip: true,
			minWidth: 120,
		},
		{
			prop: "orl_renewal_money",
			label: "订单金额",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "orp_no",
			label: "支付单号",
			color: "--text-color",
			showTooltip: true,
			minWidth: 150
		},
		{
			prop: "m_mobile",
			label: "绑定用户",
			showTooltip: true,
		},
		{
			prop: "pay_time",
			label: "支付时间",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "e_name",
			label: "续费充电桩",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "s_name",
			label: "归属电站",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "useRange",
			label: "有效期",
			color: "--text-color",
			showTooltip: true,
		},
	]

	const extraParame = ref({
		m_id: '',
		type: 'order',
		order_type: '1'
	})
	const changeOrder = () => {
		if (extraParame.value.order_type == 1) {
			tableColumns.value = dfColumns;
			nextTick(() => {
				refRoleTable.value.tableLoad();
			})
		} else if (extraParame.value.order_type == 2) {
			tableColumns.value = chargeColumns;
			nextTick(() => {
				refRoleTable.value.tableLoad();
			})
		} else if (extraParame.value.order_type == 3) {
			tableColumns.value = renewColumns;
			nextTick(() => {
				refRoleTable.value.tableLoad();
			})
		} else if (extraParame.value.order_type == 4) {
			activeName.value = '1';
			getIntDetail();
		}
	}
	const menberInfo = ref("")
	const show = async (id) => {
		tableColumns.value = dfColumns;
		extraParame.value.m_id = id;
		extraParame.value.order_type = '1';
		activeName.value = '1';
		await Member.getMemberInfo({
			m_id: extraParame.value.m_id,
			type: "basic"
		}).then((res) => {
			if (res.Code === 200) {
				menberInfo.value = res.Data
			} else {
				ElMessage.error(res.Message);
			}
		});
		detailsDialog.value.show();
		await nextTick(() => {
			refRoleTable.value.tableLoad();
		})
	}



	const activeName = ref('1'); // 积分tab
	const integralTable = ref(null); // 积分明细表格对象
	const integralDatas = ref([]); // 全部积分
	const ind_type = ref(''); // 积分类型（1：收入2：支出）空为全部
	const integralTotal = ref('0'); // 合计
	const wholeColumns = ref([{
			prop: "ind_integral",
			label: "积分",
			type: "customRender",
		},
		{
			prop: "f_ind_source",
			label: "来源",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "f_ind_ctime",
			label: "操作时间",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "o_sn",
			label: "关联订单",
			color: "--text-color",
			showTooltip: true,
		},
	]);
	/**
	 * 
	 * 切换积分类型
	 * 
	 * */
	const handleClick = () => {
		if (activeName.value == '1') {
			// 全部
			ind_type.value = '';
		} else if (activeName.value == '2') {
			// 收入
			ind_type.value = '1';
		} else {
			// 支出
			ind_type.value = '2';
		}
		getIntDetail();
	}
	/**
	 * 
	 * 会员订单明细内获得积分明细
	 * 
	 * */
	const getIntDetail = () => {
		let params = {
			m_id: extraParame.value.m_id,
			ind_type: ind_type.value,
		};
		Member.getIntDetail(params).then(res => {
			// console.log("res", res, params)
			if (res.Code == 200) {
				integralTotal.value = res.Data.total;
				integralDatas.value = res.Data.list;
				nextTick(() => {
					integralTable.value.tableLoad();
				})
			} else {
				ElMessage.error(res.Message);
			}
		})
	}
	defineExpose({
		show
	})
</script>

<style lang="scss" scoped>
	.bg-gray {
		background: #f3f4f8
	}

	.flex-wrap {
		flex-wrap: wrap;
	}

	.main-cnt {
		height: calc(100% - 280px) !important;
		margin: 0;
	}

	.margin-top {
		margin-top: 15px;
	}
	.justify-between{
		justify-content: space-between;
	}

	.basic-item {
		width: 30%;
		display: flex;
		justify-content: space-between;
		padding: 0px 50px 0px 50px;
		line-height: 30px;

		span:nth-child(1) {
			color: #959aa0;
		}

		span:nth-child(2) {
			color: #333;
		}
	}

	.content {
		padding: 0 0 15px 0 !important;
	}

	.order-charge {
		font-family: "Source Han Sans CN";

		.el-row {
			border: none;
		}


		.bg-color {
			background-color: #F4FDFA;
		}

		.text-red {
			color: var(--error-color);
		}
	}

	.amount {
		margin-top: 10px;
		justify-content: space-between;
	}

	.amount-title {
		font-size: 18px;
	}

	.order-total {
		margin-left: 123px;
		color: #1AC994;
	}

	.total-paid {
		margin-right: 290px;
		color: #1AC994;
	}

	.order-left {
		margin-bottom: 20px;
		padding: 20px;
	}

	.charging-state {
		margin-bottom: 20px;
	}

	.charged {
		padding: 20px 30px;
	}

	.time-on {
		padding: 22px 20px;
	}

	.order-right {
		padding: 20px 15px !important;
	}

	.charging-img {
		width: 50px;
		height: 50px;
	}

	.integral-total {
		margin-left: 20px;
	}
</style>